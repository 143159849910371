/**
 * Service that wraps the web-installed PostHog analytics client.
 * @note Using the global PostHog client since we cannot install the npm package due to Node version constraints.
 */
import { Injectable } from '@angular/core';

declare var posthog: any;

@Injectable({
  providedIn: 'root'
})
export class PosthogService {
  private isPosthogAvailable: boolean;

  constructor() {
    this.isPosthogAvailable = typeof posthog !== 'undefined';
		if (!this.isPosthogAvailable) {
			console.error('PostHog is not unavailable');
		}
  }

	setSession(id: string, email: string, name: string, roles: string[]) {
		if (!this.isPosthogAvailable) {
			return
		}
		posthog.identify(id, {email, name, roles})
	}

  trackEvent(eventName: string, properties?: any) {
		if (!this.isPosthogAvailable) {
			return
		}
		posthog.capture(eventName, properties);
  }
}
